$(document).on('turbo:load', function() {
  // NOTE: intercept new post modal form requests when attempting to create a new
  // post from master calendar and use Turbo.visit so we are Turbo
  // compatible. This makes requests faster and is needed for mobile compatibility
  $('body').on('shown.bs.modal', "#new-post-modal", function(e) {
    const modal = $(e.target);

    modal.find("#new-master-calendar-post-form").on("submit", function(se) {
      se.preventDefault();
      const calendarId = $(se.currentTarget).find("input[name=calendar_id]").val();
      const date = $(se.currentTarget).find("#post_date").val();
      const params = date ? `?date=${date}` : "";

      if (calendarId) {
        modal.on("hidden.bs.modal", () => Turbo.visit(`/calendars/${calendarId}/posts/new${params}`));
        modal.modal("hide");
      }

      return false;
    });
  });

  $('body').on('shown.bs.modal', "#new-post-import-modal", function(e) {
    const modal = $(e.target);

    modal.find("#new-master-calendar-post-import-form").on("submit", function(se) {
      se.preventDefault();
      const calendarId = $(se.currentTarget).find("input[name=calendar_id]").val();

      window.trackPicoUserAction('bulk_upload_calendar_selected', { calendar_id: calendarId });

      if (calendarId) {
        modal.on("hidden.bs.modal", () => Turbo.visit(`/calendars/${calendarId}/post_imports/new`));
        modal.modal("hide");
      }

      return false;
    });
  });

  $('body').on('shown.bs.modal', "#new-invite-modal", function(e) {
    const modal = $(e.target);

    modal.find("#new-master-calendar-invite-form").on("submit", function(se) {
      se.preventDefault();
      const calendarId = $(se.currentTarget).find("input[name=calendar_id]").val();

      if (calendarId) {
        modal.on("hidden.bs.modal", () => Turbo.visit(`/calendars/${calendarId}/collaborators`));
        modal.modal("hide");
      }

      return false;
    });
  });

  $('body').on('shown.bs.modal', "#new-draft-idea-modal", function(e) {
    const modal = $(e.target)

    modal.find("#new-master-calendar-draft-idea-form").on("submit", function(se) {
      se.preventDefault();

      const calendarId = $(se.currentTarget).find("input[name=calendar_id]").val();

      if (calendarId) {
        modal.on("hidden.bs.modal", () => Turbo.visit(`/calendars/${calendarId}/draft_ideas/new`));
        modal.modal("hide");
      }

      return false;
    });
  });
});
